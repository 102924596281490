export class JobSearchParams {
  loggedInConsultantId: number = 0;
  consultantId: number;
  status: string;
  leadConsultantId: number;
  writerId: number;
  name: string;
  miscId: number;
  jobSort: string;
  jobTypeId: number;
  reviewTypeId: number;
  jobNumber: number;
  showDeletedJobs: boolean;
  showOnlyPreferred: boolean;

  constructor(data: JobSearchParams) {
    this.showDeletedJobs = false;
    this.showOnlyPreferred = false;
    this.status = data ? data.status : '';
    this.consultantId = data ? data.consultantId : 0;
    this.leadConsultantId = data ? data.leadConsultantId : 0;
    this.writerId = data ? data.writerId : 0;
    this.name = data ? data.name : '';
    this.miscId = data ? data.miscId : 0;
    this.jobSort = '';
    this.jobTypeId = 0;
    this.reviewTypeId = 0;
    this.jobNumber = null;
  }
}

