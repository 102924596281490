import { DOCUMENT } from '@angular/common';
import { Component, Inject, Renderer2 } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { SidenavService } from './layout/sidenav/sidenav.service';
import { ThemeService } from '../@fury/services/theme.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Platform } from '@angular/cdk/platform';
import { SplashScreenService } from '../@fury/services/splash-screen.service';
import { AzureMonitoringService } from '../_services/logging.service';

@Component({
  selector: 'fury-root',
  templateUrl: './app.component.html'
})
export class AppComponent {

  constructor(private sidenavService: SidenavService,
    private iconRegistry: MatIconRegistry,
    private renderer: Renderer2,
    private themeService: ThemeService,
    @Inject(DOCUMENT) private document: Document,
    private platform: Platform,
    private route: ActivatedRoute,
    public router: Router,
    private splashScreenService: SplashScreenService) {

    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('style'))
    ).subscribe(queryParamMap => this.themeService.setStyle(queryParamMap.get('style')));

    this.iconRegistry.setDefaultFontSetClass('material-icons-outlined');
    this.themeService.theme$.subscribe(theme => {
      if (theme[0]) {
        this.renderer.removeClass(this.document.body, theme[0]);
      }

      this.renderer.addClass(this.document.body, theme[1]);
    });

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    this.sidenavService.addItems([
      {
        name: 'JMS',
        position: 2,
        type: 'subheading',
        customClass: 'first-subheading'
      },
      {
        name: 'Jobs',
        routeOrFunction: '/jms/job',
        icon: 'work_outline',
        position: 3,
      },
      {
        name: 'Internal Time',
        routeOrFunction: '/jms/internal-time',
        icon: 'schedule',
        position: 4,
        filter: 'isInternal',
      },
      {
        name: 'Expense Claims',
        routeOrFunction: '/jms/expense-claims',
        icon: 'receipt_long',
        position: 5,
        filter: 'isInternal',
      },
      {
        name: 'Purchase Logs',
        routeOrFunction: '/jms/purchase-logs',
        icon: 'assignment',
        position: 6,
        filter: 'isInternal',
      },  
      {
        name: 'Consultants',
        routeOrFunction: 'jms/consultants',
        icon: 'group',
        position: 7,
        filter: 'isInternal',
      },
      {
        name: 'Invoices',
        routeOrFunction: 'jms/invoices',
        icon: 'list_alt',
        position: 8,
        filter: 'isInternal',
      },
      {
        name: 'Billing',
        routeOrFunction: 'jms/billing',
        icon: 'monetization_on',
        position: 9,
        filter: 'isInternal',
      },
      //{
      //  name: 'Daily Agenda',
      //  routeOrFunction: '/coming-soon',
      //  icon: 'fact_check',
      //  position: 10,
      //  filter: 'isInternal',
      //},
      {
        name: 'System Tables',
        icon: 'table_rows',
        position: 11,
        filter: 'isAdminGroup',
        subItems: [
          {
            name: 'Review Types',
            routeOrFunction: '/jms/system-tables/review-types',
            position: 12
          },
          {
            name: 'Invoice Detail Types',
            routeOrFunction: '/jms/system-tables/invoice-detail-types',
            position: 13
          },
          {
            name: 'Specialties',
            routeOrFunction: '/jms/system-tables/specialties',
            position: 14
          },
          {
            name: 'Roles',
            routeOrFunction: '/jms/system-tables/roles',
            position: 15
          },
          {
            name: 'Job Types',
            routeOrFunction: '/jms/system-tables/job-types',
            position: 16
          },
          {
            name: 'Service Codes',
            routeOrFunction: '/jms/system-tables/service-codes',
            position: 17
          },
          {
            name: 'Object Codes',
            routeOrFunction: '/jms/system-tables/object-codes',
            position: 18
          },
          {
            name: 'Budget Line Numbers',
            routeOrFunction: '/jms/system-tables/budget-line-numbers',
            position: 19
          },
          {
            name: 'Defaults',
            routeOrFunction: '/jms/system-tables/defaults',
            position: 20
          },
          {
            name: 'Mileage Rates',
            routeOrFunction: '/jms/system-tables/mileage-rates',
            position: 21
          },
          {
            name: 'Indirect Costs',
            routeOrFunction: '/jms/system-tables/indirect-costs',
            position: 21
          }
        ]
      }
    ]);
  }
}
