export class Profile {
  consultantId: number = 0;
  cnFirstName: string = '';
  cnLastName: string = '';
  cnAddress: string = '';
  cnAddress2: string = '';
  cnCity: string = '';
  cnState: string = '';
  cnZipCode: string = '';
  cnPhone: string = '';
  cnFax: string = '';
}
