import { ChangeDetectionStrategy, Component, OnInit, Input } from '@angular/core';
import { LIST_FADE_ANIMATION } from '../../../../@fury/shared/list.animation';
import { Router } from '@angular/router';

@Component({
  selector: 'fury-toolbar-notifications',
  templateUrl: './toolbar-notifications.component.html',
  styleUrls: ['./toolbar-notifications.component.scss'],
  animations: [...LIST_FADE_ANIMATION],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarNotificationsComponent implements OnInit {

  @Input() numNotifications: number;
  notifications: any[];
  isOpen: boolean;

  constructor(private router: Router) {
  }

  ngOnInit() {
    this.notifications = [
    //  {
    //    icon: 'notifications',
    //    name: 'There are 5 invoices needing your attention',
    //    time: 'few sec ago',
    //    read: false,
    //    colorClass: ''
    //  },
    //  {
    //    icon: 'shopping_basket',
    //    name: 'User bought your template',
    //    time: '23 min ago',
    //    read: true,
    //    colorClass: 'primary'
    //  },
    //  {
    //    icon: 'eject',
    //    name: 'Server Crashed',
    //    time: 'an hour ago',
    //    read: true,
    //    colorClass: 'accent'
    //  },
    //  {
    //    icon: 'cached',
    //    name: 'New user registered',
    //    time: '6 hours ago',
    //    read: true,
    //    colorClass: ''
    //  },
    //  {
    //    icon: 'code',
    //    name: 'John added you as friend',
    //    time: 'yesterday',
    //    read: true,
    //    colorClass: ''
    //  }
    ];
  }

  openInvoicesNeedingAttention() {
    if (this.numNotifications > 0) {
      this.router.navigate(['/jms/invoices/needsattention']);
    }
  }

  get tooltip() {
    if (this.numNotifications == 1) {return '1 invoice needs your attention' }
    else {return this.numNotifications + ' invoices need your attention'}
  }

  markAsRead(notification) {
    notification.read = true;
  }

  dismiss(notification, event) {
    event.stopPropagation();
    this.notifications.splice(this.notifications.indexOf(notification), 1);
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  onClickOutside() {
    this.isOpen = false;
  }

  markAllAsRead() {
    this.notifications.forEach(notification => notification.read = true);
  }
}
