import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatVerticalStepperScrollerDirective } from './stepper.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [MatVerticalStepperScrollerDirective],
  exports: [MatVerticalStepperScrollerDirective]
})
export class StepperModule {
}
