<ng-container *ngIf="user as userInfo; else login">
  <div class="toolbar-user" (furyClickOutside)="onClickOutside()" style="display: flex;">
    <button class="button" mat-button (click)="toggleDropdown()" [class.open]="isOpen" style="display: flex;">
      <span style="display: flex; flex-direction: row; align-items: center; justify-content: center;">
        <!--<img class="avatar" src="assets/img/avatars/default.jpg">-->
        <mat-icon>account_circle</mat-icon>
        <span class="name hide-ltsm">{{userInfo.name}}</span>
        <mat-icon class="icon hide-ltsm">keyboard_arrow_down</mat-icon>
      </span>
    </button>

    <div class="dropdown" [class.open]="isOpen">
      <div class="content">
        <div class="list">
          <div class="list-item" matRipple (click)=updateProfile()>
            <mat-icon class="list-item-icon">person_outline</mat-icon>
            <span>Profile</span>
          </div>
          <div class="list-item" matRipple (click)=changePassword()>
            <mat-icon class="list-item-icon">vpn_key</mat-icon>
            <span>Change My Password</span>
          </div>
          <!--<div class="list-item" matRipple>
    <mat-icon class="list-item-icon">settings</mat-icon>
    <span>Settings</span>
  </div>
  <div class="list-item" matRipple>
    <mat-icon class="list-item-icon">help</mat-icon>
    <span>Help</span>
  </div>-->
          <!--<mat-divider></mat-divider>-->
          <div class="list-item" matRipple (click)="logout()">
            <mat-icon class="list-item-icon">exit_to_app</mat-icon>
            <span>Logout</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  </ng-container>

<ng-template #login>
  <div class="toolbar-user" (furyClickOutside)="onClickOutside()" style="display: flex;">
    <button class="button" mat-button (click)="toggleDropdown()" [class.open]="isOpen" style="display: flex;">
      <span style="display: flex; flex-direction: row; align-items: center; justify-content: center;">
          <mat-icon>account_circle</mat-icon>
        <mat-icon class="icon hide-ltsm">keyboard_arrow_down</mat-icon>
      </span>
    </button>

    <div class="dropdown" [class.open]="isOpen">
      <div class="content">
        <div class="list">
          <!--<div class="list-item" matRipple>
            <mat-icon class="list-item-icon">help</mat-icon>
            <span>Help</span>
          </div>-->
          <!--<mat-divider></mat-divider>-->
          <div class="list-item" matRipple [routerLink]="['/login']">
            <mat-icon class="list-item-icon">exit_to_app</mat-icon>
            <span>Sign In</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
