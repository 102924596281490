import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // Needed for Touch functionality of Material Components
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { PendingInterceptorModule } from '../@fury/shared/loading-indicator/pending-interceptor.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarConfig } from '@angular/material/snack-bar';
import { AuthenticationService } from '../_services/authentication.service';
import { JwtInterceptor } from '../_helpers/jwt.interceptor';
import { ErrorInterceptor } from '../_helpers/error.interceptor';
import { ListService } from '../_services/lists.service';
import { ConsultantService } from '../_services/consultant.service';
import { JobRequestService } from './pages/job-request/job-request.service';
import { appInitializer } from '../_helpers/app.initializer';
import { ErrorHandlerService } from '../_services/errorhandler.service';
import { AzureMonitoringService } from '../_services/logging.service';
import { ReportViewerModule } from './pages/jms/reportviewer/report-viewer.module';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

import { RecaptchaModule, RecaptchaFormsModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask';

@NgModule({
  imports: [
    // Angular Core Module // Don't remove!
    BrowserModule,
    BrowserAnimationsModule,
    DragDropModule,
    HttpClientModule,

    // Fury Core Modules
    AppRoutingModule,

    // Layout Module (Sidenav, Toolbar, Quickpanel, Content)
    LayoutModule,

    // Displays Loading Bar when a Route Request or HTTP Request is pending
    PendingInterceptorModule,

    //Report viewer
    NgxExtendedPdfViewerModule,
    ReportViewerModule,

    //Captcha
    RecaptchaModule,
    RecaptchaFormsModule,

    //Ngx-Mask
    NgxMaskDirective,
    NgxMaskPipe,

    // Register a Service Worker (optional)
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [
    AuthenticationService,
    ListService,
    ConsultantService,
    JobRequestService,
    AzureMonitoringService,
    provideEnvironmentNgxMask(),
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthenticationService] },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as MatFormFieldDefaultOptions
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 5000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom'
      } as MatSnackBarConfig
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: "<YOUR_KEY>" } as RecaptchaSettings,
    },
  ]
})
export class AppModule {
}
