import { JobSearchParams } from '../../../../_models/job-search-params.model';
import { AuthenticationService } from '../../../../_services/authentication.service';
import { InternalTimeSearchParams } from '../internal-time/internal-time-search-params.model';
import { PurchaseLogsSearchParams } from '../purchase-logs/purchase-logs-list/purchase-logs-search-params.model';
import { BillingSearchParams } from '../billing/billing-list/billing-search-params.model';

export class ReportData {
  reportName: string = '';
  parameters: ReportParameter[] = [];
  options: ReportOption[] = [];
  hasExcel: boolean = false;
  reportHelpers: ReportHelpers = new ReportHelpers();
  authenticationService: AuthenticationService;
}

export class ReportParameter {
  key: string;
  value: any;

  constructor(data) {
    this.key = data.key;
    this.value = data.value;
  }
}

export class ReportOption {
  label: string;
  reportname: string;
  isChecked: boolean = false;
}

export class ReportHelpers {
  public getJobSearchParameters(searchParams: JobSearchParams, authenticationService: AuthenticationService): any[] {
    let parameters: any[] = [];

    
    parameters.push(new ReportParameter({ key: "LoggedInConsultantID", value: authenticationService.currentUserValue.id }));
    parameters.push(new ReportParameter({ key: "ShowEvals", value: true }));

    if (searchParams.jobNumber) {
      parameters.push(new ReportParameter({ key: "JobNumber", value: searchParams.jobNumber }));
    }
    else {
      if (searchParams.consultantId) {
        parameters.push(new ReportParameter({ key: "ConsultantID", value: searchParams.consultantId }));
      }
      if (searchParams.status) {
        parameters.push(new ReportParameter({ key: "Status", value: searchParams.status }));
      }
      if (searchParams.leadConsultantId) {
        parameters.push(new ReportParameter({ key: "LeadConsultantID", value: searchParams.leadConsultantId }));
      }
      if (searchParams.writerId) {
        parameters.push(new ReportParameter({ key: "WriterID", value: searchParams.writerId }));
      }
      if (searchParams.name) {
        parameters.push(new ReportParameter({ key: "Name", value: searchParams.name }));
      }
      if (searchParams.miscId) {
        parameters.push(new ReportParameter({ key: "MiscID", value: searchParams.miscId }));
      }
      if (searchParams.jobSort) {
        parameters.push(new ReportParameter({ key: "JobSort", value: searchParams.jobSort }));
      }
      if (searchParams.jobTypeId) {
        parameters.push(new ReportParameter({ key: "JobTypeId", value: searchParams.jobTypeId }));
      }
      if (searchParams.reviewTypeId) {
        parameters.push(new ReportParameter({ key: "ReviewTypeID", value: searchParams.reviewTypeId }));
      }
      if (searchParams.showDeletedJobs) {
        parameters.push(new ReportParameter({ key: "ShowDeletedJobs", value: searchParams.showDeletedJobs }));
      }
      if (searchParams.showOnlyPreferred) {
        parameters.push(new ReportParameter({ key: "ShowOnlyPreferred", value: searchParams.showOnlyPreferred }));
      }
    }

    return parameters;
  }

  public getInternalTimeSearchParameters(searchParams: InternalTimeSearchParams): any[] {
    let parameters: any[] = [];

    if (searchParams.jobId) {
      parameters.push(new ReportParameter({ key: "JobID", value: searchParams.jobId }));
    }
    if (searchParams.consultantId) {
      parameters.push(new ReportParameter({ key: "ConsultantID", value: searchParams.consultantId }));
    }
    if (searchParams.serviceCodeId) {
      parameters.push(new ReportParameter({ key: "ServiceCodeID", value: searchParams.serviceCodeId }));
    }
    if (searchParams.fromDate) {
      parameters.push(new ReportParameter({ key: "FromDate", value: this.formatDate(searchParams.fromDate) }));
    }
    if (searchParams.toDate) {
      parameters.push(new ReportParameter({ key: "ToDate", value: this.formatDate(searchParams.toDate) }));
    }

    return parameters;
  }
  
  public getPurchaseLogSearchParameters(searchParams: PurchaseLogsSearchParams): any[] {
    let parameters: any[] = [];
   
    if (searchParams.consultantId) {
      parameters.push(new ReportParameter({ key: "ConsultantID", value: searchParams.consultantId }));
    }
    if (searchParams.fromDate) {
      parameters.push(new ReportParameter({ key: "FromDate", value: this.formatDate(searchParams.fromDate) }));
    }
    if (searchParams.toDate) {
      parameters.push(new ReportParameter({ key: "ToDate", value: this.formatDate(searchParams.toDate) }));
    }

    return parameters;
  }

  public getBillingSearchParameters(searchParams:BillingSearchParams): any[] {
    let parameters: any[] = [];

    if (searchParams.readyToSend) {
      parameters.push(new ReportParameter({ key: "ReadyToSend", value: searchParams.readyToSend }));
    }
    if (searchParams.sentToAcct) {
      parameters.push(new ReportParameter({ key: "SentToAcct", value: searchParams.sentToAcct }));
    }
    if (searchParams.sentToClient) {
      parameters.push(new ReportParameter({ key: "SentToClient", value: searchParams.sentToClient }));
    }
    if (searchParams.paidByClient) {
      parameters.push(new ReportParameter({ key: "PaidByClient", value: searchParams.paidByClient }));
    }
    if (searchParams.readyFromDate && searchParams.readyToSend) {
      parameters.push(new ReportParameter({ key: "ReadyFromDate", value: this.formatDate(searchParams.readyFromDate) }));
    }
    if (searchParams.readyToDate && searchParams.readyToSend) {
      parameters.push(new ReportParameter({ key: "ReadyToDate", value: this.formatDate(searchParams.readyToDate) }));
    }
    if (searchParams.sentToAcctFromDate && searchParams.sentToAcct) {
      parameters.push(new ReportParameter({ key: "SentToAcctFromDate", value: this.formatDate(searchParams.sentToAcctFromDate) }));
    }
    if (searchParams.sentToAcctToDate && searchParams.sentToAcct) {
      parameters.push(new ReportParameter({ key: "SentToAcctToDate", value: this.formatDate(searchParams.sentToAcctToDate) }));
    }
    if (searchParams.sentToClientFromDate && searchParams.sentToClient) {
      parameters.push(new ReportParameter({ key: "SentToClientFromDate", value: this.formatDate(searchParams.sentToClientFromDate) }));
    }
    if (searchParams.sentToClientToDate && searchParams.sentToClient) {
      parameters.push(new ReportParameter({ key: "SentToClientToDate", value: this.formatDate(searchParams.sentToClientToDate) }));
    }
    if (searchParams.paidByClientFromDate && searchParams.paidByClient) {
      parameters.push(new ReportParameter({ key: "PaidByClientFromDate", value: this.formatDate(searchParams.paidByClientFromDate) }));
    }
    if (searchParams.paidByClientToDate && searchParams.paidByClient) {
      parameters.push(new ReportParameter({ key: "PaidByClientToDate", value: this.formatDate(searchParams.paidByClientToDate) }));
    }
    if (searchParams.jobLeadId) {
      parameters.push(new ReportParameter({ key: "JobLeadID", value: searchParams.jobLeadId }));
    }
    if (searchParams.jobStatus) {
      parameters.push(new ReportParameter({ key: "JobStatus", value: searchParams.jobStatus }));
    }
    if (searchParams.jobId) {
      parameters.push(new ReportParameter({ key: "JobID", value: searchParams.jobId }));
    }

    return parameters;
  }

  public formatDate(dateValue: Date) {
    return dateValue.toLocaleDateString("en-US");
  }
}




export class JobsListReportData extends ReportData {
  jobSearchParams: JobSearchParams;
  toReportData() {
    let rd = new ReportData();
    rd.reportName = "JobsList"
    rd.parameters = this.reportHelpers.getJobSearchParameters(this.jobSearchParams, this.authenticationService);
    rd.hasExcel = true;

    let o = new ReportOption();
    o.label = "Group by Review Type";
    o.reportname = "JobsList_GroupedByReviewType";
    rd.options.push(o)

    return rd;
  }
}

export class JobsListBillingReportData extends ReportData {
  jobSearchParams: JobSearchParams;

  toReportData() {
    let rd = new ReportData();
    rd.reportName = "JobsListBilling"
    rd.parameters = this.reportHelpers.getJobSearchParameters(this.jobSearchParams, this.authenticationService);
    rd.hasExcel = true;
    return rd;
  }
}

export class JobsListInvoicesReportData extends ReportData {
  jobSearchParams: JobSearchParams;

  toReportData() {
    let rd = new ReportData();
    rd.reportName = "JobsListInvoices"
    rd.parameters = this.reportHelpers.getJobSearchParameters(this.jobSearchParams, this.authenticationService);
    rd.hasExcel = true;
    return rd;
  }
}

export class JobsListProgressReportData extends ReportData {
  jobSearchParams: JobSearchParams;

  toReportData() {
    let rd = new ReportData();
    rd.reportName = "JobsListProgress"
    rd.parameters = this.reportHelpers.getJobSearchParameters(this.jobSearchParams, this.authenticationService);
    rd.hasExcel = true;
    return rd;
  }
}

export class JobsListStatusReportData extends ReportData {
  jobSearchParams: JobSearchParams;

  toReportData() {
    let rd = new ReportData();
    rd.reportName = "JobsListStatus"
    rd.parameters = this.reportHelpers.getJobSearchParameters(this.jobSearchParams, this.authenticationService);
    rd.hasExcel = true;
    return rd;
  }
}

export class JobInfoReportData extends ReportData {
  jobId: number;

  toReportData() {
    let rd = new ReportData();
    rd.reportName = "JobInfo"
    rd.parameters = [];
    if (this.jobId) {
      rd.parameters.push(new ReportParameter({ key: "JobId", value: this.jobId }));
    }
    return rd;
  }
}

export class JobCostReportData extends ReportData {
  jobId: number;

  toReportData() {
    let rd = new ReportData();
    rd.reportName = "JobCost"
    rd.parameters = [];
    if (this.jobId) {
      rd.parameters.push(new ReportParameter({ key: "JobId", value: this.jobId }));
    }

    let o = new ReportOption();
    o.label = "Print Payment History";
    o.reportname = "JobCost_WithPaymentHistory";
    rd.options.push(o)

    return rd;
  }
}

export class JobCostEstimateReportData extends ReportData {
  jobId: number;

  toReportData() {
    let rd = new ReportData();
    rd.reportName = "JobCostEstimate"
    rd.parameters = [];
    if (this.jobId) {
      rd.parameters.push(new ReportParameter({ key: "JobId", value: this.jobId }));
    }
    rd.hasExcel = true;
    return rd;
  }
}

export class JobStatusReportData extends ReportData {
  jobId: number;

  toReportData() {
    let rd = new ReportData();
    rd.reportName = "JobStatus"
    rd.parameters = [];
    if (this.jobId) {
      rd.parameters.push(new ReportParameter({ key: "JobId", value: this.jobId }));
    }
    return rd;
  }
}

export class JobBillableReportData extends ReportData {
  jobId: number;

  toReportData() {
    let rd = new ReportData();
    rd.reportName = "JobBillNonBill"
    rd.parameters = [];
    if (this.jobId) {
      rd.parameters.push(new ReportParameter({ key: "JobId", value: this.jobId }));
    }
    rd.hasExcel = true;

    let o = new ReportOption();
    o.label = "Print Notes";
    o.reportname = "JobBillNonBill_WithNotes";
    rd.options.push(o)

    return rd;
  }
}

export class InvoiceReportData extends ReportData {
  invoiceId: number;

  toReportData() {
    let rd = new ReportData();
    rd.reportName = "Invoice"
    rd.parameters = [];
    if (this.invoiceId) {
      rd.parameters.push(new ReportParameter({ key: "InvoiceID", value: this.invoiceId }));
    }

    let o2 = new ReportOption();
    o2.label = "Landscape";
    o2.reportname = "default";
    rd.options.push(o2);

    let o3 = new ReportOption();
    o3.label = "Portrait";
    o3.reportname = "Invoice_Portrait";
    rd.options.push(o3);

    let o = new ReportOption();
    o.label = "Print Cover Sheet";
    o.reportname = "Invoice_CoverSheet";
    rd.options.push(o);

    return rd;
  }
}

export class InternalTimeByDateReportData extends ReportData {
  searchParams: InternalTimeSearchParams;

  toReportData() {
    let rd = new ReportData();
    rd.reportName = "InternalTimeByDate"
    rd.parameters = this.reportHelpers.getInternalTimeSearchParameters(this.searchParams);
    rd.hasExcel = true;
    return rd;
  }
}

export class InternalTimeByConsultantThenJobReportData extends ReportData {
  searchParams: InternalTimeSearchParams;

  toReportData() {
    let rd = new ReportData();
    rd.reportName = "InternalTimeByConsultantThenJob"
    rd.parameters = this.reportHelpers.getInternalTimeSearchParameters(this.searchParams);
    rd.hasExcel = true;

    let r = new ReportOption();
    r.label = "All";
    r.reportname = "default";
    r.isChecked = true;
    rd.options.push(r)

    let o = new ReportOption();
    o.label = "Staff Only";
    o.reportname = "InternalTimeByConsultantThenJob_StaffOnly";
    rd.options.push(o)

    let p = new ReportOption();
    p.label = "Show Bill/NoBill";
    p.reportname = "InternalTimeByConsultantThenJob_BillNoBill";
    rd.options.push(p)

    let q = new ReportOption();
    q.label = "Show Bill/NoBill - Staff Only";
    q.reportname = "InternalTimeByConsultantThenJob_BillNoBill_StaffOnly";
    rd.options.push(q)

    return rd;
  }
}

export class InternalTimeByConsultantThenTypeReportData extends ReportData {
  searchParams: InternalTimeSearchParams;

  toReportData() {
    let rd = new ReportData();
    rd.reportName = "InternalTimeByConsultantThenType"
    rd.parameters = this.reportHelpers.getInternalTimeSearchParameters(this.searchParams);
    rd.hasExcel = true;
    return rd;
  }
}

export class ExpenseClaimReportData extends ReportData {
  expenseClaimId: number;

  toReportData() {
    let rd = new ReportData();
    rd.reportName = "ExpenseClaim_WithNotes"
    rd.parameters = [];
    if (this.expenseClaimId) {
      rd.parameters.push(new ReportParameter({ key: "ExpenseClaimID", value: this.expenseClaimId }));
    }

    let o = new ReportOption();
    o.label = "Without Notes";
    o.reportname = "ExpenseClaim";
    rd.options.push(o)

    return rd;
  }
}

export class PurchaseLogReportData extends ReportData {
  purchaseLogId: number;

  toReportData() {
    let rd = new ReportData();
    rd.reportName = "PurchaseLog"
    rd.parameters = [];
    if (this.purchaseLogId) {
      rd.parameters.push(new ReportParameter({ key: "PurchaseLogID", value: this.purchaseLogId }));
    }
    return rd;
  }
}

export class PurchaseLogRangeReportData extends ReportData {
  searchParams: PurchaseLogsSearchParams;

  toReportData() {
    let rd = new ReportData();
    rd.reportName = "PurchaseLogRange"
    rd.parameters = this.reportHelpers.getPurchaseLogSearchParameters(this.searchParams);
    rd.hasExcel = true;
    return rd;
  }
}

export class ConsultantJobBillingReportData extends ReportData {
  consultantId: number = 0;
  specialtyId: number = 0;
  restrictActive: string;

  toReportData() {
    let rd = new ReportData();
    rd.reportName = "ConsultantJobBilling"
    rd.parameters = [];
    if (this.consultantId) {
      rd.parameters.push(new ReportParameter({ key: "ConsultantID", value: this.consultantId }));
    }
    if (this.specialtyId) {
      rd.parameters.push(new ReportParameter({ key: "SpecialtyID", value: this.specialtyId }));
    }
    if (this.restrictActive && this.restrictActive.length > 0) {
      rd.parameters.push(new ReportParameter({ key: "RestrictActive", value: this.restrictActive }));
    }
    rd.hasExcel = true;
    return rd;
  }
}

export class ConsultantJobsReportData extends ReportData {
  consultantId: number = 0;
  specialtyId: number = 0;
  restrictActive: string;

  toReportData() {
    let rd = new ReportData();
    rd.reportName = "ConsultantJobs"
    rd.parameters = [];
    if (this.consultantId) {
      rd.parameters.push(new ReportParameter({ key: "ConsultantID", value: this.consultantId }));
    }
    if (this.specialtyId) {
      rd.parameters.push(new ReportParameter({ key: "SpecialtyID", value: this.specialtyId }));
    }
    if (this.restrictActive && this.restrictActive.length > 0) {
      rd.parameters.push(new ReportParameter({ key: "RestrictActive", value: this.restrictActive }));
    }
    rd.hasExcel = true;
    return rd;
  }
}

export class BillingSummaryReportData extends ReportData {
  clientBillId: number;

  toReportData() {
    let rd = new ReportData();
    rd.reportName = "ClientBillSummary"
    rd.parameters = [];
    if (this.clientBillId) {
      rd.parameters.push(new ReportParameter({ key: "ClientBillId", value: this.clientBillId }));
    }
    rd.hasExcel = true;
    return rd;
  }
}

export class BillingDetailReportData extends ReportData {
  clientBillId: number;

  toReportData() {
    let rd = new ReportData();
    rd.reportName = "ClientBillDetail"
    rd.parameters = [];
    if (this.clientBillId) {
      rd.parameters.push(new ReportParameter({ key: "ClientBillID", value: this.clientBillId }));
    }
    rd.hasExcel = true;
    return rd;
  }
}

export class BillingListReportData extends ReportData {
  searchParams: BillingSearchParams;

  toReportData() {
    let rd = new ReportData();
    rd.reportName = "ClientBillList"
    rd.parameters = this.reportHelpers.getBillingSearchParameters(this.searchParams);
    rd.hasExcel = true;
    return rd;
  }
}

  export class BillingAgingReportData extends ReportData {
  searchParams: BillingSearchParams;

  toReportData() {
    let rd = new ReportData();
    rd.reportName = "ClientBillAging"
    rd.parameters = this.reportHelpers.getBillingSearchParameters(this.searchParams);
    rd.hasExcel = true;
    return rd;
  }
}

export class AgendaReportData extends ReportData {

  toReportData() {
    let rd = new ReportData();
    rd.reportName = "Agenda"
    rd.parameters = [];
    rd.hasExcel = true;
    return rd;
  }
}



