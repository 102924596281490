export class ConsultantSearchParams {
  jobId: number = 0;
  firstName: string = '';
  lastName: string = '';
  specialtyId: number = 0;
  showSpecialties: boolean = false;
  internalOnly: boolean = false;
  assignedYesNo: string = '';
  restrictActive: string = '1';
  showLeadOnly: boolean = false;
  showWriterOnly: boolean = false;
  conSort: string = '';
  includeKCSOSStaff: boolean = false;
}

