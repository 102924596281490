<div class="search-wrapper" style="display: flex; flex-direction: row;" [ngClass]="{ 'focus': focused, 'has-input': input }"
     (furyClickOutside)="closeDropdown()">
  <mat-icon class="search-icon">search</mat-icon>
  <input type="search"
         autocomplete="off"
         spellcheck="false"
         class="search-input"
         [(ngModel)]="input"
         (focus)="openDropdown()"
         placeholder="Search...">

  <div class="search-dropdown">
    <div class="content results" *ngIf="input" style="display: flex; flex-direction: column;">
      <div class="heading" style="display: flex; flex-direction: row; align-items: center; justify-content: center;">
        <mat-icon class="icon">search</mat-icon>
        Search results for: {{ input }}
      </div>
      <div class="items">
        <div class="item" matRipple [routerLink]="['/forms/form-elements']">Form Elements</div>
        <div class="item" matRipple [routerLink]="['/editor']">WYSIWYG Editor</div>
        <div class="item" matRipple [routerLink]="['/maps/google-maps']">Google Maps</div>
        <div class="item" matRipple [routerLink]="['/components/dialogs']">Material Dialog</div>
      </div>
    </div>

    <div class="content recents" style="display: flex; flex-direction: row;" *ngIf="!input">
      <div class="recently" style="display: flex; flex-direction: column;">
        <div class="heading" style="display: flex; flex-direction: row; align-items: center; justify-content: center;">
          <mat-icon class="icon">restore</mat-icon>
          Recently Visited
        </div>
        <div class="items">
          <div class="item" matRipple *ngFor="let item of recentlyVisited" [routerLink]="item.routeOrFunction"
               (click)="closeDropdown()">{{ item.name }}
          </div>
        </div>
      </div>
      <div class="frequently" style="display: flex; flex-direction: column;">
        <div class="heading" style="display: flex; flex-direction: row; align-items: center; justify-content: center;" >
          <mat-icon class="icon">youtube_searched_for</mat-icon>
          Frequently Visited
        </div>
        <div class="items">
          <div class="item" matRipple [routerLink]="['/editor']">WYSIWYG Editor</div>
          <div class="item" matRipple [routerLink]="['/maps/google-maps']">Google Maps</div>
          <div class="item" matRipple [routerLink]="['/components/dialogs']">Material Dialog</div>
        </div>
      </div>
    </div>
  </div>
</div>
