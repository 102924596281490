export class User {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  isInternal: boolean;
  isAdmin: boolean;
  isAdminSec: boolean;
  isAccountant: boolean;
  isAdminGroup: boolean;
  canSearchJobs: boolean;
  jwtToken?: string;
  forcePasswordChange: boolean;
  numNotifications: number;

  constructor(data) { 
    this.id = data.id;
    this.email = data.email;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.isInternal = data.isInternal;
    this.isAdmin = data.isAdmin;
    this.isAdminSec = data.isAdminSec;
    this.isAccountant = data.isAccountant;
    this.isAdminGroup = data.isAdminGroup;
    this.canSearchJobs = data.canSearchJobs;
    this.jwtToken = data.jwtToken;
    this.forcePasswordChange = data.forcePasswordChange;
    this.numNotifications = data.numNotifications;
  }

  get name() {
    let name = '';

    if (this.firstName && this.lastName) {
      name = this.firstName + ' ' + this.lastName;
    } else if (this.firstName) {
      name = this.firstName;
    } else if (this.lastName) {
      name = this.lastName;
    }
    return name;
  }

}

