export class BillingSearchParams {
  readyToSend: string = ' ';
  sentToAcct: string = ' ';
  sentToClient: string = ' ';
  paidByClient: string = ' ';
  readyFromDate: Date = null;
  readyToDate: Date = null;
  sentToAcctFromDate: Date = null;
  sentToAcctToDate: Date = null;
  sentToClientFromDate: Date = null;
  sentToClientToDate: Date = null;
  paidByClientFromDate: Date = null;
  paidByClientToDate: Date = null;
  jobLeadId: number = 0;
  jobStatus: string = '';
  jobId: number = 0;
}

