import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Profile } from './profile.model';

@Injectable()
export class ProfileService {

  constructor(private http: HttpClient) {}
 
  getProfile() {
    return this.http.get<Profile>(`${environment.apiUrl}/Consultants/GetProfile`);
  }

  updateProfile(profile: Profile) {
    return this.http.post<any>(`${environment.apiUrl}/Consultants/UpdateProfile`, profile);
  }
}
