import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { RequestJobInfo } from './job-info.model';
import { RequestJobContact } from './job-contact-model';


@Injectable()
export class JobRequestService {

  constructor(private http: HttpClient) {}

  submitJobRequest(requestTypeId: number, job: RequestJobInfo, contact: RequestJobContact, evaluationTypeIds: number[]) {
    let request = {
      requestTypeId,
      job,
      contact,
      evaluationTypeIds
    }
    return this.http.post<any>(`${environment.apiUrl}/JobRequest/SubmitJobRequest`, request);
  }
}
