import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { AuthGuard } from '../_helpers/auth.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/authentication/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/authentication/register/register.module').then(m => m.RegisterModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/authentication/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/authentication/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
  },
  {
    path: 'change-password',
    loadChildren: () => import('./pages/authentication/change-password/change-password.module').then(m => m.ChangePasswordModule),
  },
  {
    path: 'change-password-force',
    loadChildren: () => import('./pages/authentication/change-password-force/change-password-force.module').then(m => m.ChangePasswordForceModule),
  },
  {
    path: 'job-request/wizard',
    loadChildren: () => import('./pages/job-request/wizard/wizard.module').then(m => m.WizardModule)
  },
  {
    path: 'job-request',
    loadChildren: () => import('./pages/job-request/menu/job-request-menu.module').then(m => m.JobRequestMenuModule)
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'jms/job',
        pathMatch: 'full'
      },
      {
        path: 'jms/job/:id',
        loadChildren: () => import('./pages/jms/jobs/job-detail/job-detail.module').then(m => m.JobDetailModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'jms/job',
        loadChildren: () => import('./pages/jms/jobs/job-list/jobs.module').then(m => m.JobsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'jms/internal-time',
        loadChildren: () => import('./pages/jms/internal-time/internal-time.module').then(m => m.InternalTimeModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'jms/expense-claims/:id',
        loadChildren: () => import('./pages/jms/expense-claims/expense-claim-detail/expense-claim-detail.module').then(m => m.ExpenseClaimDetailModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'jms/expense-claims',
        loadChildren: () => import('./pages/jms/expense-claims/expense-claims-list/expense-claims.module').then(m => m.ExpenseClaimsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'jms/purchase-logs/:id',
        loadChildren: () => import('./pages/jms/purchase-logs/purchase-log-detail/purchase-log-detail.module').then(m => m.PurchaseLogDetailModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'jms/purchase-logs',
        loadChildren: () => import('./pages/jms/purchase-logs/purchase-logs-list/purchase-logs.module').then(m => m.PurchaseLogsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'jms/consultants/:id',
        loadChildren: () => import('./pages/jms/consultants/consultant-detail/consultant-detail.module').then(m => m.ConsultantDetailModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'jms/consultants',
        loadChildren: () => import('./pages/jms/consultants/consultants-list/consultants-list.module').then(m => m.ConsultantsListModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'jms/invoices/needsattention',
        loadChildren: () => import('./pages/jms/invoices/invoices-list/invoices-list.module').then(m => m.InvoicesListModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'jms/invoices/:id',
        loadChildren: () => import('./pages/jms/invoices/invoice-detail-page/invoice-detail-page.module').then(m => m.InvoiceDetailPageModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'jms/invoices',
        loadChildren: () => import('./pages/jms/invoices/invoices-list/invoices-list.module').then(m => m.InvoicesListModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'jms/billing/job/:id',
        loadChildren: () => import('./pages/jms/billing/billing-list/billing.module').then(m => m.BillingModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'jms/billing/:id',
        loadChildren: () => import('./pages/jms/billing/billing-detail/billing-detail.module').then(m => m.BillingDetailModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'jms/billing',
        loadChildren: () => import('./pages/jms/billing/billing-list/billing.module').then(m => m.BillingModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'jms/system-tables/review-types',
        loadChildren: () => import('./pages/jms/system-tables/review-types/review-types.module').then(m => m.ReviewTypesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'jms/system-tables/invoice-detail-types',
        loadChildren: () => import('./pages/jms/system-tables/invoice-detail-types/invoice-detail-types.module').then(m => m.InvoiceDetailTypesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'jms/system-tables/specialties',
        loadChildren: () => import('./pages/jms/system-tables/specialties/specialties.module').then(m => m.SpecialtiesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'jms/system-tables/service-codes',
        loadChildren: () => import('./pages/jms/system-tables/service-codes/service-codes.module').then(m => m.ServiceCodesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'jms/system-tables/object-codes',
        loadChildren: () => import('./pages/jms/system-tables/object-codes/object-codes.module').then(m => m.ObjectCodesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'jms/system-tables/roles',
        loadChildren: () => import('./pages/jms/system-tables/roles/roles.module').then(m => m.RolesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'jms/system-tables/job-types',
        loadChildren: () => import('./pages/jms/system-tables/job-types/job-types.module').then(m => m.JobTypesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'jms/system-tables/budget-line-numbers',
        loadChildren: () => import('./pages/jms/system-tables/budget-line-numbers/budget-line-numbers.module').then(m => m.BudgetLineNumbersModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'jms/system-tables/defaults',
        loadChildren: () => import('./pages/jms/system-tables/defaults/defaults.module').then(m => m.DefaultsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'jms/system-tables/mileage-rates',
        loadChildren: () => import('./pages/jms/system-tables/mileage-rates/mileage-rates.module').then(m => m.MileageRatesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'jms/system-tables/indirect-costs',
        loadChildren: () => import('./pages/jms/system-tables/indirect-costs/indirect-costs.module').then(m => m.IndirectCostsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'coming-soon',
        loadChildren: () => import('./pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule),
      }
    ]
  },
  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
