import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest, HttpHeaders } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ReportData, ReportParameter } from './report.model';

@Injectable()
export class ReportsService {

  constructor(private http: HttpClient) {}

  getReport(options: ReportData): Observable<any> {

    //var opts = new ReportData();
    //opts.reportName = "Jobs";
    //opts.parameters = [];
    //var param1 = new ReportParameter(JobTypeId);
    //param1.key = 'JobTypeId';
    //param1.value = 3;
    //opts.parameters.push(param1); 

    let headers = new HttpHeaders();
    let params = new HttpParams().set('options', JSON.stringify(options));
    headers = headers.set('Accept', 'application/pdf');

    return this.http.get(`${environment.apiUrl}/Reports/GetReport`, { headers, params, responseType: 'blob' })
  }

  getReportExcel(options: ReportData): Observable<any> {

    let headers = new HttpHeaders();
    let params = new HttpParams().set('options', JSON.stringify(options));
    headers = headers.set('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');

    return this.http.get(`${environment.apiUrl}/Reports/GetReportExcel`, { headers, params, responseType: 'blob' })
  }
}
