<div class="toolbar" style="display: flex; flex-direction: row;">
  <div style="display: flex; flex-direction: row; align-items: center; justify-content: flex-start; flex:1;">
    <fury-toolbar-sidenav-mobile-toggle (openSidenav)="openSidenav.emit()"></fury-toolbar-sidenav-mobile-toggle>

    <div [routerLink]="['/']" class="toolbar-logo">
      <img src="../../../assets/img/FCMAT_horizontal.jpg" style="height: 50px;" />
    </div>

  </div>

  <div style="display: flex; flex-direction: row;">

    <button (click)="openJobRequests()" class="toolbar-button agenda-button"
            style="display: flex;" mat-button type="button">
      <mat-icon class="icon">
        work_outline
      </mat-icon>
      <!--<div>
        <mat-icon class="icon" style="position: absolute;">
          work_outline
        </mat-icon>
        <mat-icon class="icon" style="position: relative; font-size: 12px; top: 5px; -webkit-top: 5px;">
          add
        </mat-icon>
      </div>-->


      <!--create_new_folder-->
      <!--add_comment-->
      <div style="line-height: 1em;">Job Requests</div>
    </button>

    <button (click)="openAgenda()" class="toolbar-button agenda-button"
            style="display: flex;" mat-button type="button"
            *ngIf="(_authenticationService.user$ | async) && (_authenticationService.user$ | async).isInternal">
      <mat-icon class="icon">
        fact_check
      </mat-icon>
      <div>Agenda</div>
    </button>

    <fury-toolbar-notifications class="toolbar-button"
                                [numNotifications]="(_authenticationService.user$ | async).numNotifications"
                                *ngIf="(_authenticationService.user$ | async) && (_authenticationService.user$ | async).isInternal"></fury-toolbar-notifications>

    <fury-toolbar-user class="toolbar-button"
                       [user]="_authenticationService.user$ | async"
                       (userLogout)="userLogout()"></fury-toolbar-user>

    <!--<fury-toolbar-quickpanel-toggle (openQuickPanel)="openQuickPanel.emit()"
                              class="toolbar-button"></fury-toolbar-quickpanel-toggle>-->
  </div>
</div>

