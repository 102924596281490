<div class="sidenav" style="display: flex; flex-direction: column;">
  <div style="margin-top: 10px;"> </div>
  <fury-scrollbar class="sidenav-items" style="overflow-y:auto; overflow-x:hidden; flex:1;">
    <fury-sidenav-item *ngFor="let item of items$ | async" [item]="item" [level]="0"></fury-sidenav-item>
  </fury-scrollbar>

  <div class="sidenav-toolbar" style="display: flex;">
    <span style="flex:1;"><!-- fill space --></span>

    <div>
      <button (click)="toggleCollapsed()" class="sidenav-toolbar-collapse-toggle" mat-icon-button style="margin-right: 8px;display: flex;align-items: center;justify-content: center;">
        <mat-icon *ngIf="!collapsed" class="sidenav-toolbar-collapse-icon">radio_button_checked</mat-icon>
        <mat-icon *ngIf="collapsed" class="sidenav-toolbar-collapse-icon">radio_button_unchecked</mat-icon>
      </button>
    </div>
  </div>
</div>
