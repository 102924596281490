import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { map } from 'rxjs/operators';
import { JobTypeListItem } from '../_models/job-type-list-item.model';
import { EvaluationTypeListItem } from '../_models/evaluation-type-list-item.model';
import { ServiceCodeListItem } from '../_models/service-code-list-item.model';
import { ObjectCodeListItem } from '../_models/object-code-list-item.model';
import { RoleListItem } from '../_models/role-list-item.model';
import { JobListItem } from '../_models/job-list-item.model';
import { SpecialtyListItem } from '../_models/specialty-list-item.model';
import { InvoiceStatusListItem } from '../_models/invoice-status-list-item.model';

export const DefaultPageSize = 100;

@Injectable({ providedIn: 'root' })
export class ListService {

  public JobStatuses = [
    { 'label': 'All', 'value': 'A' },
    { 'label': 'Open/Pending', 'value': 'OP' },
    { 'label': 'Open', 'value': 'O' },
    { 'label': 'Closed', 'value': 'C' },
    { 'label': 'Pending', 'value': 'P' },
  ];

  public States = [
    { 'label': 'Alabama', 'value': 'AL' },
    { 'label': 'Alaska', 'value': 'AK' },
    { 'label': 'American Samoa', 'value': 'AS' },
    { 'label': 'Arizona', 'value': 'AZ' },
    { 'label': 'Arkansas', 'value': 'AR' },
    { 'label': 'California', 'value': 'CA' },
    { 'label': 'Colorado', 'value': 'CO' },
    { 'label': 'Connecticut', 'value': 'CT' },
    { 'label': 'Delaware', 'value': 'DE' },
    { 'label': 'Washington DC', 'value': 'DC' },
    { 'label': 'States of Micronesia', 'value': 'FM' },
    { 'label': 'Florida', 'value': 'FL' },
    { 'label': 'Georgia', 'value': 'GA' },
    { 'label': 'Guam', 'value': 'GU' },
    { 'label': 'Hawaii', 'value': 'HI' },
    { 'label': 'Idaho', 'value': 'ID' },
    { 'label': 'Illinois', 'value': 'IL' },
    { 'label': 'Indiana', 'value': 'IN' },
    { 'label': 'Iowa', 'value': 'IA' },
    { 'label': 'Kansas', 'value': 'KS' },
    { 'label': 'Kentucky', 'value': 'KY' },
    { 'label': 'Louisiana', 'value': 'LA' },
    { 'label': 'Maine', 'value': 'ME' },
    { 'label': 'Marshall Islands', 'value': 'MH' },
    { 'label': 'Maryland', 'value': 'MD' },
    { 'label': 'Massachusetts', 'value': 'MA' },
    { 'label': 'Michigan', 'value': 'MI' },
    { 'label': 'Minnesota', 'value': 'MN' },
    { 'label': 'Mississippi', 'value': 'MS' },
    { 'label': 'Missouri', 'value': 'MO' },
    { 'label': 'Montana', 'value': 'MT' }, 
    { 'label': 'Nebraska', 'value': 'NE' },
    { 'label': 'Nevada', 'value': 'NV' },
    { 'label': 'New Hampshire', 'value': 'NH' },
    { 'label': 'New Jersey', 'value': 'NJ' },
    { 'label': 'New Mexico', 'value': 'NM' },
    { 'label': 'New York', 'value': 'NY' },
    { 'label': 'North Carolina', 'value': 'NC' },
    { 'label': 'North Dakota', 'value': 'ND' },
    { 'label': 'Northern Mariana Islands', 'value': 'MP' },
    { 'label': 'Ohio', 'value': 'OH' },
    { 'label': 'Oklahoma', 'value': 'OK' },
    { 'label': 'Oregon', 'value': 'OR' },
    { 'label': 'Palau', 'value': 'PW' },
    { 'label': 'Pennsylvania', 'value': 'PA' },
    { 'label': 'Puerto Rico', 'value': 'PR' },
    { 'label': 'Rhode Island', 'value': 'RI' },
    { 'label': 'South Carolina', 'value': 'SC' },
    { 'label': 'South Dakota', 'value': 'SD' },
    { 'label': 'Tennessee', 'value': 'TN' },
    { 'label': 'Texas', 'value': 'TX' },
    { 'label': 'Utah', 'value': 'UT' },
    { 'label': 'Vermont', 'value': 'VT' },
    { 'label': 'Virgin Islands', 'value': 'VI' },
    { 'label': 'Virginia', 'value': 'VA' },
    { 'label': 'Washington', 'value': 'WA' },
    { 'label': 'West Virginia', 'value': 'WV' },
    { 'label': 'Wisconsin', 'value': 'WI' },
    { 'label': 'Wyoming', 'value': 'WY' }
  ];

  
  private _jobTypes = new BehaviorSubject<JobTypeListItem[]>(null);
  jobTypes$: Observable<JobTypeListItem[]> = this._jobTypes.asObservable();

  private _jobsAll = new BehaviorSubject<JobListItem[]>(null);
  jobsAll$: Observable<JobListItem[]> = this._jobsAll.asObservable();

  private _evalTypes = new BehaviorSubject<EvaluationTypeListItem[]>(null);
  evalTypes$: Observable<EvaluationTypeListItem[]> = this._evalTypes.asObservable();

  private _serviceCodes = new BehaviorSubject<ServiceCodeListItem[]>(null);
  serviceCodes$: Observable<ServiceCodeListItem[]> = this._serviceCodes.asObservable();

  private _serviceCodesAll = new BehaviorSubject<ServiceCodeListItem[]>(null);
  serviceCodesAll$: Observable<ServiceCodeListItem[]> = this._serviceCodesAll.asObservable();

  private _objectCodes = new BehaviorSubject<ObjectCodeListItem[]>(null);
  objectCodes$: Observable<ObjectCodeListItem[]> = this._objectCodes.asObservable();

  private _specialties = new BehaviorSubject<SpecialtyListItem[]>(null);
  specialties$: Observable<SpecialtyListItem[]> = this._specialties.asObservable();

  private _invoiceStatuses = new BehaviorSubject<InvoiceStatusListItem[]>(null);
  invoiceStatuses$: Observable<InvoiceStatusListItem[]> = this._invoiceStatuses.asObservable();

  private _roles = new BehaviorSubject<RoleListItem[]>(null);
  roles$: Observable<RoleListItem[]> = this._roles.asObservable();

  constructor(private http: HttpClient) {}

  public getJobTypeDescription(jobTypeId: number): Observable<string> {
    return this.jobTypes$.pipe(
      map(list => {
        if (list) {
          var jobType = list.find(c => c.jobTypeId == jobTypeId);
          if (!jobType) {
            return "Not Yet Assigned";
          }
          else return jobType.jtDescription;
        }
        else {
          return "Loading...";
        }
      }))
  }

  public getServiceCodeName(serviceCodeId: number): Observable<string> {
    return this.serviceCodesAll$.pipe(
      map(list => {
        if (list) {
        var serviceCode = list.find(c => c.serviceCodeId == serviceCodeId);
        if (!serviceCode) {
          return "Not Yet Assigned";
        }
          else return serviceCode.scDescription;
        }
        else {
          return "Loading...";
        }
      }))
  }

  public getSpecialtyName(specialtyId: number): Observable<string> {
    return this.specialties$.pipe(
      map(list => {
        if (list) {
          var specialty = list.find(c => c.specialtyId == specialtyId);
          if (!specialty) {
            return "Not Yet Assigned";
          }
          else return specialty.scDescription;
        }
        else {
          return "Loading...";
        }
      }))
  }

  public getEvaluationDescription(evalTypeId: number): Observable<string> {
    return this.evalTypes$.pipe(
      map(list => {
        if (list) {
          var evalType = list.find(c => c.evaluationTypeId == evalTypeId);
          if (!evalType) {
            return "[unavailable]";
          }
          else return evalType.vtDescription;
        }
        else {
          return "Loading...";
        }
      }))
  }

  public resetJobTypes(): void {
    this._jobTypes.next(null);
    this.getJobTypes();
  }

  public getJobTypes(): void {
    if (!this._jobTypes.getValue()) {
     
     this.http.get<JobTypeListItem[]>(`${environment.apiUrl}/Helper/GetJobTypes`)
        .subscribe(list => {
          this._jobTypes.next(list);
        });
    }
  }

  public getJobs(): void {
    if (!this._jobsAll.getValue()) {

      this.http.get<JobListItem[]>(`${environment.apiUrl}/Helper/GetJobs`)
        .subscribe(list => {
          this._jobsAll.next(list);
        });
    }
  }

  public resetEvaluationTypes(): void {
    this._evalTypes.next(null);
    this.getEvaluationTypes();
  }

  public getEvaluationTypes(): void {
    if (!this._evalTypes.getValue()) {

      this.http.get<EvaluationTypeListItem[]>(`${environment.apiUrl}/Helper/GetEvaluationTypes`)
        .subscribe(list => {
          this._evalTypes.next(list);
        });
    }
  }

  public resetServiceCodes(): void {
    this._serviceCodes.next(null);
    this._serviceCodesAll.next(null);
    this.getServiceCodes();
    this.getServiceCodesAll();
  }

  public getServiceCodeNote(serviceCodeId: number): Observable<string> {
    return this.serviceCodes$.pipe(
      map(list => {
        if (list) {
          var serviceCode = list.find(c => c.serviceCodeId == serviceCodeId);
          if (!serviceCode) {
            return '';
          }
          else return serviceCode.scNote;
        }
        else {
          return '';
        }
      }))
  }

  public getServiceCodes(): void {
    if (!this._serviceCodes.getValue()) {

      this.http.get<ServiceCodeListItem[]>(`${environment.apiUrl}/Helper/GetServiceCodes`)
        .subscribe(list => {
          this._serviceCodes.next(list);
        });
    }
  }

  public getServiceCodesAll(): void {
    if (!this._serviceCodesAll.getValue()) {

      this.http.get<ServiceCodeListItem[]>(`${environment.apiUrl}/Helper/GetAllServiceCodes`)
        .subscribe(list => {
          this._serviceCodesAll.next(list);
        });
    }
  }

  public resetObjectCodes(): void {
    this._objectCodes.next(null);
    this.getObjectCodes();
  }

  public getObjectCodes(): void {
    if (!this._objectCodes.getValue()) {

      this.http.get<ObjectCodeListItem[]>(`${environment.apiUrl}/Helper/GetObjectCodes`)
        .subscribe(list => {
          this._objectCodes.next(list);
        });
    }
  }

  public resetSpecialties(): void {
    this._specialties.next(null);
    this.getSpecialties();
  }

  public getSpecialties(): void {
    if (!this._specialties.getValue()) {

      this.http.get<SpecialtyListItem[]>(`${environment.apiUrl}/Helper/GetSpecialties`)
        .subscribe(list => {
          this._specialties.next(list);
        });
    }
  }

  public resetRoles(): void {
    this._roles.next(null);
    this.getRoles();
  }

  public getRoles(): void {
    if (!this._roles.getValue()) {

      this.http.get<RoleListItem[]>(`${environment.apiUrl}/Helper/GetRoles`)
        .subscribe(list => {
          this._roles.next(list);
        });
    }
  }

  public getInvoiceStatuses(): void {
    if (!this._invoiceStatuses.getValue()) {

      this.http.get<InvoiceStatusListItem[]>(`${environment.apiUrl}/Helper/GetInvoiceStatuses`)
        .subscribe(list => {
          this._invoiceStatuses.next(list);
        });
    }
  }

}
