import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Profile } from './profile.model';
import { ProfileService } from './profile.service';
import { ListService } from '../../../../_services/lists.service';

@Component({
  selector: 'jms-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  form: UntypedFormGroup;
  currentProfile: Profile = new Profile();

  constructor(private _profileService: ProfileService,
    public _listService: ListService,
    private dialogRef: MatDialogRef<ProfileComponent>,
    private fb: UntypedFormBuilder) {

    this.form = this.fb.group(new Profile());

    this.form.get('cnAddress').setValidators([Validators.maxLength(100)]);
    this.form.get('cnAddress2').setValidators([Validators.maxLength(50)]);
    this.form.get('cnCity').setValidators([Validators.maxLength(50)]);
  }

  ngOnInit() {
    this._profileService.getProfile().subscribe(p => {
      this.currentProfile = p;
      this.fillForm();
    });
  }

  fillForm() {
    Object.keys(this.currentProfile).forEach(k => {
      let control = this.form.get(k);
      if (control)
        control.setValue(this.currentProfile[k], { onlySelf: true });
    });
  }

  getFormInfo() {
    this.currentProfile = new Profile();
    Object.keys(this.currentProfile).forEach(k => {
      let control = this.form.get(k);
      if (control)
        this.currentProfile[k] = control.value;
    });
  }

  save() {
    if (this.form.valid) {
      this.getFormInfo();
      this._profileService.updateProfile(this.currentProfile).subscribe(() => this.dialogRef.close())
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
