<div mat-dialog-title style="width:475px;">My Profile</div>

<form [formGroup]="form" (ngSubmit)="save()">
  <mat-dialog-content>

    <div style="margin-bottom: 1em;">
      <span class="fcmat-value">
        {{currentProfile.cnFirstName}}&nbsp; {{currentProfile.cnLastName}}
      </span>
    </div>

      <mat-form-field style="display: flex;">
        <mat-label>Street</mat-label>
        <input type="text" formControlName="cnAddress" matInput>
        <mat-error *ngIf="form.get('cnAddress').hasError('maxlength')">Max length is 100 characters</mat-error>
      </mat-form-field>

      <mat-form-field style="display: flex;">
        <mat-label>Street2</mat-label>
        <input type="text" formControlName="cnAddress2" matInput>
        <mat-error *ngIf="form.get('cnAddress2').hasError('maxlength')">Max length is 50 characters</mat-error>
      </mat-form-field>

      <mat-form-field style="display: flex;">
        <mat-label>City</mat-label>
        <input type="text" formControlName="cnCity" matInput>
        <mat-error *ngIf="form.get('cnCity').hasError('maxlength')">Max length is 50 characters</mat-error>
      </mat-form-field>

    <div style="display: flex; flex-direction: row; gap: 8px;">
      <mat-form-field style="display: flex;">
        <mat-label>State</mat-label>
        <mat-select formControlName="cnState">
          <mat-option *ngFor="let state of _listService.States" [value]="state.value">
            {{state.label}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field style="display: flex;">
        <mat-label>Zipcode</mat-label>
        <input type="text" formControlName="cnZipCode" matInput>
      </mat-form-field>
    </div>

    <div style="display: flex; flex-direction: row; gap: 8px;">
      <mat-form-field style="display: flex;">
        <mat-label>Phone Number</mat-label>
        <input type="text" formControlName="cnPhone" matInput mask='(000) 000-0000'>
      </mat-form-field>
      <mat-form-field style="display: flex;">
        <mat-label>Fax Number</mat-label>
        <input type="text" formControlName="cnFax" matInput mask='(000) 000-0000'>
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions style="display: flex; flex-direction: row; justify-content: flex-end;">
    <button mat-button (click)="cancel(); $event.preventDefault()">CANCEL</button>
    <button mat-flat-button [disabled]="!form.valid" color="primary">UPDATE PROFILE</button>
  </mat-dialog-actions>
</form>
