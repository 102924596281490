import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { User } from '../_models/user.model';
import { ConsultantService } from './consultant.service';
import { Router } from '@angular/router';
import { ListService } from './lists.service';
import { AzureMonitoringService } from './logging.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  private _pollInterval = 10000;
  private _userSubject = new BehaviorSubject<User>(null);
  user$: Observable<User> = this._userSubject.asObservable();

  constructor(
    private router: Router,
    private http: HttpClient,
    private _loggingService: AzureMonitoringService,
    private _consultantsService: ConsultantService,
    public _listService: ListService  ) { }

  public get currentUserValue(): User {
    return this._userSubject.value;
  }

  setUser(user: User) {
    if (!!user) {
      this._loggingService.setUserId(user.id.toString());
    } else {
      this._loggingService.clearUserId();
    }

    this._userSubject.next(user);
    this.getAllLists();

    if (user && user.isInternal) {
      this.getnotifications();
    }

    if (user && user.forcePasswordChange) {
      this.router.navigate(['/change-password-force']);
    }
  }

  getAllLists() {
    this._consultantsService.getConsWriters();
    this._consultantsService.getConsLeads();
    this._consultantsService.getConsActive();
    this._consultantsService.getConsAll();
    this._consultantsService.resetSearchParams();
    this._listService.getJobTypes();
    this._listService.getJobs();
    this._listService.getEvaluationTypes();
    this._listService.getServiceCodes();
    this._listService.getServiceCodesAll();
    this._listService.getObjectCodes();
    this._listService.getSpecialties();
    this._listService.getInvoiceStatuses();
    this._listService.getRoles();
  }

  login(username: string, password: string) {
    return this.http.post<User>(`${environment.apiUrl}/user/authenticate`, { username, password }, { withCredentials: true })
      .pipe(map((user) => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        
        let currentUser = new User(user);
        this.setUser(currentUser);
        this.startRefreshTokenTimer();
        return currentUser;
      }));
  }

  logout() {
    this.http.post<any>(`${environment.apiUrl}/user/RevokeToken`, {}, { withCredentials: true }).subscribe();
    this.stopRefreshTokenTimer();
    this.setUser(null);
    this._consultantsService.setJobSearchParams(null);
    this.router.navigate(['/login']);
  }

  refreshToken() {
    return this.http.post<any>(`${environment.apiUrl}/user/RefreshToken`, {}, { withCredentials: true })
      .pipe(map((user) => {
        let currentUser = new User(user);
        this.setUser(currentUser);
        this.startRefreshTokenTimer();
        return user;
      }));
  }

  sendResetLink(email: string) {
    return this.http.post<any>(`${environment.apiUrl}/user/sendResetLink`, { email });
  }

  resetPassword(password: string, jwtToken: string) {
    var tempUser = new User({ id: 0 });
    tempUser.jwtToken = jwtToken;
    this.setUser(tempUser);
    return this.http.post<any>(`${environment.apiUrl}/user/resetPassword`, { password })
      .pipe(map(user => {
        let currentUser = new User(user);
        this.setUser(currentUser);
        this.startRefreshTokenTimer();
        return currentUser;
      }));
  }

  changePassword(oldpassword: string, newpassword: string,) {
    return this.http.post<any>(`${environment.apiUrl}/user/changePassword`, { oldpassword, newpassword });
  }

  forceChangePassword(oldpassword: string, newpassword: string,) {
    return this.http.post<any>(`${environment.apiUrl}/user/ForceChangePassword`, { oldpassword, newpassword }).pipe(map(user => {
      let currentUser = new User(user);
      this.setUser(currentUser);
      this.startRefreshTokenTimer();
      return currentUser;
    }));
  }

  // helper methods

  private refreshTokenTimeout;

  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(this.currentUserValue.jwtToken.split('.')[1]));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  // notifications

  private setUserNotifications(numNotifications: number) {
    var user = this._userSubject.value;
    if (user) {
      user.numNotifications = numNotifications;
      this._userSubject.next(user);
    }
  }

  public getnotifications() {
    if (this._userSubject.value && this._userSubject.value.isInternal) {
      return this.http.get<any>(`${environment.apiUrl}/user/GetNumInvoicesNeedingAttention`).subscribe(n => {
        this.setUserNotifications(n);
        setTimeout(() => this.getnotifications(), this._pollInterval);
      });
    }
  }

}
