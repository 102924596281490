import { ChangeDetectorRef, Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { fadeInUpAnimation } from '../../../../@fury/animations/fade-in-up.animation';
import { AuthenticationService } from '../../../../_services/authentication.service';
import { first } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'FCMAT-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  animations: [fadeInUpAnimation]
})
export class ChangePasswordComponent implements OnInit {

  form: UntypedFormGroup;

  inputType = 'password';
  visible = false;
  error = false;
  returnUrl: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private dialogRef: MatDialogRef<ChangePasswordComponent>,
    private _authenticationService: AuthenticationService
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.form = this.fb.group({
      oldpassword: ['', [Validators.required, Validators.minLength(8)]],
      newpassword: ['', [Validators.required, Validators.minLength(8)]]
    });

  }

  hasError() {
    return this.error;
  }

  get f() { return this.form.controls; }

  save() {
    if (this.form.valid) {
      this._authenticationService.changePassword(this.f.oldpassword.value, this.f.newpassword.value).subscribe(
        () => { this.dialogRef.close(true) },
        (err) => {
          this.error = err;
        }
      );
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  toggleVisibility() {
    if (this.visible) {
      this.inputType = 'password';
      this.visible = false;
      this.cd.markForCheck();
    } else {
      this.inputType = 'text';
      this.visible = true;
      this.cd.markForCheck();
    }
  }
}
