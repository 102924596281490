import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { environment } from '../environments/environment';
import { map } from 'rxjs/operators';
import { JobSearchParams } from '../_models/job-search-params.model';
import { ConsultantListItem } from '../_models/consultant-list-item.model';
import { ConsultantSearchParams } from '../_models/consultant-search-params.model';
import { InternalTimeSearchParams } from '../app/pages/jms/internal-time/internal-time-search-params.model';
import { ExpenseClaimsSearchParams } from '../app/pages/jms/expense-claims/expense-claims-list/expense-claims-search-params.model';
import { PurchaseLogsSearchParams } from '../app/pages/jms/purchase-logs/purchase-logs-list/purchase-logs-search-params.model';
import { InvoiceSearchParams } from '../_models/invoice-search-params.model';
import { BillingSearchParams } from '../app/pages/jms/billing/billing-list/billing-search-params.model';
import { ConsultantDetails } from '../_models/consultant-detail.model';
import { JobListItem } from '../_models/job-list-item.model';

@Injectable({ providedIn: 'root' })
export class ConsultantService {

  private _jobSearchParams = new BehaviorSubject<JobSearchParams>(null);
  jobSearchParams$: Observable<JobSearchParams> = this._jobSearchParams.asObservable();

  private _internalTimeSearchParams = new BehaviorSubject<InternalTimeSearchParams>(null);
  internalTimeSearchParams$: Observable<InternalTimeSearchParams> = this._internalTimeSearchParams.asObservable();

  private _expenseClaimsSearchParams = new BehaviorSubject<ExpenseClaimsSearchParams>(null);
  expenseClaimsSearchParams$: Observable<ExpenseClaimsSearchParams> = this._expenseClaimsSearchParams.asObservable();

  private _purchaseLogsSearchParams = new BehaviorSubject<PurchaseLogsSearchParams>(null);
  purchaseLogsSearchParams$: Observable<PurchaseLogsSearchParams> = this._purchaseLogsSearchParams.asObservable();

  private _consultantsSearchParams = new BehaviorSubject<ConsultantSearchParams>(null);
  consultantsSearchParams$: Observable<ConsultantSearchParams> = this._consultantsSearchParams.asObservable();

  private _invoicesSearchParams = new BehaviorSubject<InvoiceSearchParams>(null);
  invoicesSearchParams$: Observable<InvoiceSearchParams> = this._invoicesSearchParams.asObservable();

  private _billingSearchParams = new BehaviorSubject<BillingSearchParams>(null);
  billingSearchParams$: Observable<BillingSearchParams> = this._billingSearchParams.asObservable();

  private _consWriters = new BehaviorSubject<ConsultantListItem[]>(null);
  consWriters$: Observable<ConsultantListItem[]> = this._consWriters.asObservable();

  private _consLeads = new BehaviorSubject<ConsultantListItem[]>(null);
  consLeads$: Observable<ConsultantListItem[]> = this._consLeads.asObservable();

  private _consActive = new BehaviorSubject<ConsultantListItem[]>(null);
  consActive$: Observable<ConsultantListItem[]> = this._consActive.asObservable();

  private _consAll = new BehaviorSubject<ConsultantListItem[]>(null);
  consAll$: Observable<ConsultantListItem[]> = this._consAll.asObservable();

  constructor(private http: HttpClient) { }

  public setJobSearchParams(params: JobSearchParams) {
    this._jobSearchParams.next(params);
  }

  public setInternalTimeSearchParams(params: InternalTimeSearchParams) {
    this._internalTimeSearchParams.next(params);
  }

  public setExpenseClaimsSearchParams(params: ExpenseClaimsSearchParams) {
    this._expenseClaimsSearchParams.next(params);
  }

  public setPurchaseLogsSearchParams(params: PurchaseLogsSearchParams) {
    this._purchaseLogsSearchParams.next(params);
  }

  public setConsultantsSearchParams(params: ConsultantSearchParams) {
    this._consultantsSearchParams.next(params);
  }

  public setInvoicesSearchParams(params: InvoiceSearchParams) {
    this._invoicesSearchParams.next(params);
  }

  public setBillingSearchParams(params: BillingSearchParams) {
    this._billingSearchParams.next(params);
  }

  public getConsultantName(consultantId: number): Observable<string> {
    return this.consAll$.pipe(
      map(list => {
        if (list) {
          var consultant = list.find(c => c.consultantId == consultantId);
          if (!consultant) {
            return "Not Yet Assigned";

          }
          else return (consultant.cnFirstName + ' ' + consultant.cnLastName).trim();
        }
        else {
          return "Loading...";
        }
      }))
  }

  public loadJobSearchParams(): void  {
    if (!this._jobSearchParams.getValue()) {
      this.http.get<JobSearchParams>(`${environment.apiUrl}/Consultants/GetJobSearchParams`)
        .subscribe(p => {
          let params = new JobSearchParams(p);
          this._jobSearchParams.next(params);
        });
    }
  }

  public resetSearchParams(): void {
    const it = new InternalTimeSearchParams();
    it.fromDate = this.todayLastYear();
    const ec = new ExpenseClaimsSearchParams();
    ec.fromDate = this.todayLastYear();
    const pl = new PurchaseLogsSearchParams();
    pl.fromDate = this.todayLastYear();

    this._internalTimeSearchParams.next(it);
    this._expenseClaimsSearchParams.next(ec);
    this._purchaseLogsSearchParams.next(pl);
    this._consultantsSearchParams.next(new ConsultantSearchParams());
    this._invoicesSearchParams.next(new InvoiceSearchParams());
    this._billingSearchParams.next(new BillingSearchParams());
  }

  private todayLastYear(): Date {
    let date = new Date();
    date.setFullYear(date.getFullYear() - 1);
    return date;
  }

  public getConsWriters(): void {
    if (!this._consWriters.getValue()) {
      var searchParams = new ConsultantSearchParams();
      searchParams.restrictActive = '1';
      searchParams.showWriterOnly = true;

      let params = new HttpParams().set('options', JSON.stringify(searchParams));
      this.http.get<ConsultantListItem[]>(`${environment.apiUrl}/Consultants/GetList`, { params })
        .subscribe(list => {
          this._consWriters.next(list);
        });
    }
  }

  public getConsLeads(): void {
    if (!this._consLeads.getValue()) {
      var searchParams = new ConsultantSearchParams();
      searchParams.restrictActive = '1';
      searchParams.showLeadOnly = true;

      let params = new HttpParams().set('options', JSON.stringify(searchParams));
      this.http.get<ConsultantListItem[]>(`${environment.apiUrl}/Consultants/GetList`, { params })
        .subscribe(list => {
          this._consLeads.next(list);
        });
    }
  }

  public getConsAll(): void {
    if (!this._consAll.getValue()) {
      var searchParams = new ConsultantSearchParams();

      let params = new HttpParams().set('options', JSON.stringify(searchParams));
      this.http.get<ConsultantListItem[]>(`${environment.apiUrl}/Consultants/GetList`, { params })
        .subscribe(list => {
          this._consAll.next(list);
        });
    }
  }

  public getConsActive(): void {
    if (!this._consActive.getValue()) {
      var searchParams = new ConsultantSearchParams();
      searchParams.restrictActive = '1';

      let params = new HttpParams().set('options', JSON.stringify(searchParams));
      this.http.get<ConsultantListItem[]>(`${environment.apiUrl}/Consultants/GetList`, { params })
        .subscribe(list => {
          this._consActive.next(list);
        });
    }
  }

  public getConsultantsListForJob(jobId: number, firstName: string, lastName: string, specialtyId: number, assigned: boolean ) {
    var searchParams = new ConsultantSearchParams();
    searchParams.jobId = jobId;
    searchParams.firstName = firstName;
    searchParams.lastName = lastName;
    searchParams.specialtyId = specialtyId;
    searchParams.showSpecialties = true;
    searchParams.internalOnly = false;
    searchParams.assignedYesNo = assigned ? 'Yes' : 'No';
    searchParams.restrictActive = '1';

    let params = new HttpParams().set('options', JSON.stringify(searchParams));
    return this.http.get<ConsultantListItem[]>(`${environment.apiUrl}/Consultants/GetList`, { params })
  }

  public getConsultantsList(csp: ConsultantSearchParams) {
    let params = new HttpParams().set('options', JSON.stringify(csp));
    return this.http.get<ConsultantListItem[]>(`${environment.apiUrl}/Consultants/GetList`, { params })
  }

  deactivate(id: number) {
    return this.http.post<any>(`${environment.apiUrl}/Consultants/Deactivate`, id);
  }

  activate(id: number) {
    return this.http.post<any>(`${environment.apiUrl}/Consultants/Activate`, id);
  }

  public getConsultantDetails(id: number) {
    let params = new HttpParams().set('id', id.toString());
    return this.http.get<ConsultantDetails>(`${environment.apiUrl}/Consultants/GetConsultantDetails`, { params })
  }

  public addUpdateConsultant(cons: ConsultantDetails) {
    cons.consultantInfo.cnDailyRate = parseFloat(cons.consultantInfo.cnDailyRate.toString().replace(/[^\d\.\-]/g, ""))
    return this.http.post<ConsultantDetails>(`${environment.apiUrl}/Consultants/AddUpdateConsultant`, cons);
  }

  public updateConsultantSpecialties(cons: ConsultantDetails) {
    return this.http.post<any>(`${environment.apiUrl}/Consultants/UpdateConsultantSpecialties`, cons);
  }

  public updateConsultantJobSearchParams(cons: ConsultantDetails) {
    return this.http.post<any>(`${environment.apiUrl}/Consultants/UpdateConsultantJobSearchParams`, cons);
  }

  public updateConsultantPassword(consId: number, password: string, forceReset: boolean) {
    var options = { consultantId: consId, password: password, forceReset: forceReset };
    return this.http.post<any>(`${environment.apiUrl}/Consultants/UpdateConsultantPassword`, options);
  }

  public getJobsForConsultant() {
    return this.http.get<JobListItem[]>(`${environment.apiUrl}/Consultants/GetJobsForConsultant`)
  }

}
