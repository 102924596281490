// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: false,
  googleMapsApiKey: '',
  backend: '/', // Put your backend here
  apiUrl: '.',
  appInsights: {
    instrumentationKey: 'd957aeb1-d0fb-4866-880d-2c8ec863f04e'
  }
};
