import { Directive } from '@angular/core';

@Directive({
  selector: '[furyPageLayoutFooter],fury-page-layout-footer',
  host: {
    class: 'fury-page-layout-footer'
  }
})
export class PageLayoutFooterDirective {

  constructor() { }

}

